import * as Sentry from "@sentry/nextjs";
import { isEmpty, isEqual } from "lodash";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useInterval } from "react-use";

import { serviceStatusSeverityEnum } from "@/enums/networkEnum";
import { SERVICE_STATUS_ENDPOINT } from "@/settings";
import { validateUrlString } from "@/utils/formValidationUtils";
import { useLocalStorage, useValueRef } from "@/utils/hookUtils";

const ServiceStatusContext = createContext();

const getServiceStatusSeverity = ({ indicator }) => {
  if (!indicator) return;

  /* 
    Fallback for Vonage service status indicators not specified in the status section
    but I think should have a notice i.e. "maintenance"
    https://vonageapi.statuspage.io/api#status
  */
  if (!serviceStatusSeverityEnum[indicator]) {
    return serviceStatusSeverityEnum.minor;
  }

  return indicator;
};

const ServiceStatusProvider = ({ children }) => {
  const [serviceStatusData, setServiceStatusData] = useState({});

  const [lastServiceStatusTimestamp, setLastServiceStatusTimestamp] =
    useLocalStorage({ key: "lastServiceStatusTimestamp" });

  const isServiceStatusIndicatorShown = useMemo(() => {
    if (isEmpty(serviceStatusData)) return false;

    const { severity, timestamp } = serviceStatusData || {};

    const isServiceStatusSameAsPrevServiceStatus = isEqual(
      timestamp,
      lastServiceStatusTimestamp,
    );

    if (isServiceStatusSameAsPrevServiceStatus) return false;
    if (!serviceStatusSeverityEnum[severity]) return false;
    return severity !== serviceStatusSeverityEnum.none;
  }, [lastServiceStatusTimestamp, serviceStatusData]);

  const handleFetchServiceStatusData = useCallback(
    async ({ isSignOut = false } = {}) => {
      if (process.env.NODE_ENV === "development") return;

      const isValidUrl = validateUrlString(SERVICE_STATUS_ENDPOINT);

      if (!isValidUrl) return;

      try {
        const serviceStatusUrl = new URL(SERVICE_STATUS_ENDPOINT);
        const data = await fetch(serviceStatusUrl).then((response) =>
          response.json(),
        );

        const {
          page: { updated_at: timestamp } = {},
          status: { description, indicator } = {},
        } = data;

        const severity = getServiceStatusSeverity({ indicator });
        const serviceStatus = {
          timestamp,
          description,
          severity,
        };

        if (isSignOut) {
          setLastServiceStatusTimestamp();
        }

        setServiceStatusData(serviceStatus);
      } catch (error) {
        Sentry.captureException(error);
      }
    },
    [setLastServiceStatusTimestamp],
  );

  const valueRefs = useValueRef({
    setLastServiceStatusTimestamp,
    handleFetchServiceStatusData,
  });

  const handleCloseServiceStatusIndicator = useCallback(() => {
    /* Record the last dismissed message timestamp. */
    valueRefs.setLastServiceStatusTimestamp(serviceStatusData.timestamp);
    setServiceStatusData();
  }, [valueRefs, serviceStatusData.timestamp]);

  const serviceStatusContextValue = useMemo(() => {
    return {
      serviceStatusData,
      isServiceStatusIndicatorShown,
      onFetchServiceStatusData: handleFetchServiceStatusData,
      onCloseServiceStatusIndicator: handleCloseServiceStatusIndicator,
    };
  }, [
    serviceStatusData,
    isServiceStatusIndicatorShown,
    handleFetchServiceStatusData,
    handleCloseServiceStatusIndicator,
  ]);

  /* Poll data for almost real-time updates every minute. */
  useInterval(handleFetchServiceStatusData, 60000);

  useEffect(() => {
    handleFetchServiceStatusData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <ServiceStatusContext.Provider value={serviceStatusContextValue}>
      {children}
    </ServiceStatusContext.Provider>
  );
};

export const useServiceStatus = () => useContext(ServiceStatusContext);

export default ServiceStatusProvider;
