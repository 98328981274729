import { ApolloClient, HttpLink, InMemoryCache, split } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { WebSocketLink } from "@apollo/client/link/ws";
import { getMainDefinition } from "@apollo/client/utilities";
import * as Sentry from "@sentry/nextjs";
import { ApolloLink } from "apollo-link";
import { RetryLink } from "apollo-link-retry";
import SerializingLink from "apollo-link-serialize";
import { isEmpty, isNil, uniqBy } from "lodash";

import * as reportingUtils from "@/utils/reportingUtils";
import { getServerSettings } from "@/utils/serverUtils";

const getAuthErrorLink = ({ authErrorCallback }) => {
  const authErrorLink = onError((error) => {
    const { graphQLErrors = [], networkError = {} } = error;
    const isAuthGraphQLError = graphQLErrors.find(
      ({ code }) =>
        code === "MISSING_AUTHORIZATION_ERROR" ||
        code === "INVALID_TOKEN_ERROR",
    );

    const isAuthNetworkError = networkError.statusCode === 401;

    if (isAuthGraphQLError || isAuthNetworkError) authErrorCallback();
  });

  return authErrorLink;
};

const getSentryErrorLink = () => {
  const sentryErrorLink = onError((error) => {
    const { operation } = error;

    const definition = getMainDefinition(operation.query);
    const isSubscription =
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription";

    if (isSubscription) {
      const errorMessage =
        error?.response?.errors?.[0]?.message ||
        error?.graphQLErrors?.[0]?.message ||
        error?.networkError?.message ||
        "Unknown subscription error";

      Sentry.captureException(new Error(`Subscription error: ${errorMessage}`));
    }
  });

  return sentryErrorLink;
};

const getRetryLink = () => {
  /*
    This will only handle retries for a network error, not a GraphQL error.
    Reference: https://www.apollographql.com/docs/react/api/link/apollo-link-retry/
  */
  const retryLink = new RetryLink({
    delay: { initial: 1000, max: 1000 * 60, jitter: true },

    attempts: (count, operation, error) => {
      /* If unauthorized do not retry */
      if (error?.response?.status === 401) return false;

      const definition = getMainDefinition(operation.query);
      const isSubscription =
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription";

      /* Retry connection if subscription failed, limit to 5 attempts */
      if (isSubscription) return count < 5;

      return false;
    },
  });

  return retryLink;
};

const mergeResultsPaginatedQuery = (
  existing,
  incoming,
  { args, variables },
) => {
  const { offset } = args || {};
  const { shouldUseIncoming } = variables || {};

  if (shouldUseIncoming) return incoming;
  if (!offset) return incoming;
  if (isEmpty(existing)) return incoming;

  return {
    ...incoming,
    results: [...existing.results, ...incoming.results],
  };
};

const mergeSimpleArrayPaginatedQuery = (
  existing,
  incoming,
  { args, variables },
) => {
  const { offset } = args || {};
  const { shouldUseIncoming } = variables || {};

  if (shouldUseIncoming) return incoming;
  if (!offset) return incoming;
  if (isEmpty(existing)) return incoming;

  return [...existing, ...incoming];
};

const readResultsPaginatedQuery = (existing, { args, readField }) => {
  const { id } = args || {};

  if (id && existing?.results) {
    const result = existing.results.find(
      (item) => readField("id", item) === id,
    );

    if (!result) return;
    return { ...existing, results: [result], totalCount: 1 };
  }

  return existing;
};

const readSimpleArrayPaginatedQuery = (existing, { args, readField }) => {
  const { id } = args || {};

  if (id && existing) {
    const result = existing.find((item) => readField("id", item) === id);
    return [result];
  }

  return existing;
};

export const createApolloClient = ({
  accessToken,
  subscriptionClient,
  authErrorCallback,
}) => {
  const { graphQLUrl } = getServerSettings();

  const httpLink = new HttpLink({
    uri: graphQLUrl,
    headers: {
      authorization: accessToken ? `Bearer ${accessToken}` : undefined,
    },
  });

  const wsLink = subscriptionClient
    ? new WebSocketLink(subscriptionClient)
    : undefined;

  /* Switch between httpLink and wsLink depending on the operation being performed */
  const requestLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);

      const isSubscription =
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription";

      return !isSubscription;
    },
    httpLink,
    wsLink,
  );

  const retryLink = getRetryLink();

  /* Responsible for forcing a logout if there is a problem with authorization in a operation */
  const authErrorLink = getAuthErrorLink({ authErrorCallback });

  /* Responsible for logging errors to Sentry for requests made */
  const sentryErrorLink = getSentryErrorLink();

  const serializingLink = new SerializingLink();

  const link = ApolloLink.from([
    serializingLink,
    retryLink,
    sentryErrorLink,
    authErrorLink,
    requestLink,
  ]);

  return new ApolloClient({
    link,
    cache: new InMemoryCache({
      typePolicies: {
        Query: {
          fields: {
            agents: {
              keyArgs: ["id"],
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },

            conversations: {
              keyArgs: [
                "id",
                "statusIn",
                "contactId",
                "isPriority",
                "allowedInboxViewInput",
              ],
              merge: (existing = {}, incoming, { args, variables }) => {
                const { results: existingResults = [] } = existing;
                const { offset } = args;
                const { shouldUseIncoming, shouldTryUseExistingConversations } =
                  variables;

                if (shouldUseIncoming) return incoming;

                if (shouldTryUseExistingConversations) {
                  if (existingResults.length === 0) return incoming;
                  return existing;
                }

                if (!offset) return incoming;

                /* Needed to handle edge cases where offset based pagination will return duplicate data */
                const mergedResults = uniqBy(
                  [...existingResults, ...incoming.results],
                  (item) => item.__ref,
                );

                return { ...incoming, results: mergedResults };
              },

              read: readResultsPaginatedQuery,
            },

            contacts: {
              keyArgs: ["id"],
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },

            crmCsvImports: {
              keyArgs: ["id"],
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },

            entities: {
              keyArgs: ["id"],
              merge: mergeSimpleArrayPaginatedQuery,
              read: readSimpleArrayPaginatedQuery,
            },

            externalCommunicationConfiguration: {
              keyArgs: ["id"],
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },

            greetings: {
              keyArgs: ["id"],
              merge: mergeSimpleArrayPaginatedQuery,
              read: readSimpleArrayPaginatedQuery,
            },

            instances: {
              keyArgs: ["id"],
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },

            instanceLists: {
              keyArgs: ["id", "entity"],
              merge: mergeSimpleArrayPaginatedQuery,
              read: readResultsPaginatedQuery,
            },

            landingPageDocumentVisitLogs: {
              keyArgs: ["id", "contactId"],
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },

            landingPageDocumentShortLinks: {
              keyArgs: ["id", "contactId"],
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },

            messagingProviderContacts: {
              keyArgs: ["id"],
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },

            messageBlastSchedules: {
              keyArgs: ["id"],
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },

            notifications: {
              keyArgs: ["id", "isUnread"],
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },

            paymentSessions: {
              keyArgs: ["id", "contactId"],
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },

            reporting: {
              merge: (existing = {}, incoming, { variables }) => {
                return reportingUtils.mergeReportingCache({
                  existing,
                  incoming,
                  variables,
                });
              },
            },

            rules: {
              keyArgs: ["id", "triggerEventCategory", "triggerEventType"],
              merge: mergeSimpleArrayPaginatedQuery,
              read: readSimpleArrayPaginatedQuery,
            },

            tags: {
              keyArgs: ["id"],
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },

            transcriptionSegments: {
              keyArgs: ["id", "recordingId"],
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },

            voiceReporting: {
              merge: (existing = {}, incoming, { variables }) => {
                return reportingUtils.mergeReportingCache({
                  existing,
                  incoming,
                  variables,
                });
              },
            },

            whatsappHsmTemplates: {
              keyArgs: ["id"],
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },
          },
        },

        ContactObject: {
          fields: {
            events: {
              merge: mergeResultsPaginatedQuery,
              read: readResultsPaginatedQuery,
            },
          },
        },

        ConversationObject: {
          fields: {
            assignee: {
              merge: (existing = {}, incoming) => {
                return { current: incoming, previous: existing.current };
              },

              read: (existing, { variables }) => {
                const { getPreviousAssignee } = variables || {};

                if (!existing) return;

                if (getPreviousAssignee) return existing.previous || null;
                return existing.current;
              },
            },

            events: {
              keyArgs: ["createdGte", "createdLt"],
              merge: (existing = {}, incoming, { variables, args }) => {
                const {
                  targetEventsObject,
                  shouldTryUseExistingConversationEvents,
                  shouldUseZeroPaginationOffsetAsFirstPage = true,
                } = variables;
                const { pointer, offset: paginationOffset } = args;

                const existingTargetObject = existing[targetEventsObject] || {};

                const existingResults = existingTargetObject.results || [];
                const incomingResults = incoming.results;

                const existingOffset = existingTargetObject.offset;
                const incomingOffset = incoming.offset;

                const shouldReturnIncoming =
                  !!pointer ||
                  (shouldUseZeroPaginationOffsetAsFirstPage &&
                    paginationOffset === 0) ||
                  isNil(existingOffset) ||
                  existingResults.length === 0;

                const { newResultsArray, newOffset } = (() => {
                  if (shouldTryUseExistingConversationEvents) {
                    if (existingResults.length === 0) {
                      return {
                        newResultsArray: incomingResults,
                        newOffset: incomingOffset,
                      };
                    }

                    return {
                      newResultsArray: existingResults,
                      newOffset: existingOffset,
                    };
                  }

                  if (shouldReturnIncoming) {
                    return {
                      newResultsArray: incomingResults,
                      newOffset: incomingOffset,
                    };
                  }

                  const shouldInsertAtStart = incomingOffset < existingOffset;

                  if (shouldInsertAtStart) {
                    return {
                      newResultsArray: [...incomingResults, ...existingResults],
                      newOffset: Math.min(existingOffset, incomingOffset),
                    };
                  }

                  return {
                    newResultsArray: [...existingResults, ...incomingResults],
                    newOffset: Math.min(existingOffset, incomingOffset),
                  };
                })();

                return {
                  ...existing,
                  [targetEventsObject]: {
                    ...incoming,
                    results: newResultsArray,
                    offset: newOffset,
                  },
                };
              },

              read: (existing = {}, { variables }) => {
                const { targetEventsObject } = variables;

                const existingTargetObject = existing[targetEventsObject];

                return existingTargetObject;
              },
            },

            conversationTags: {
              merge: mergeSimpleArrayPaginatedQuery,
              read: readSimpleArrayPaginatedQuery,
            },
          },
        },

        /* Ensure object is merged correctly for singleton object without ID field */
        FeaturePlanObject: {
          keyFields: [],
        },

        MediaObject: {
          keyFields: ["uuid"],
        },

        MessageBlastScheduleObject: {
          fields: {
            instanceLists: {
              merge: (existing, incoming) => incoming,
            },
            instanceListSnapshots: {
              merge: (existing, incoming) => incoming,
            },
            contactTags: {
              merge: (existing, incoming) => incoming,
            },
          },
        },

        RolePermissionCategoryObject: {
          keyFields: ["id", "roleId"],
        },

        RolePermissionObject: {
          keyFields: ["id", "roleId"],
        },

        RoleMutationPayload: {
          keyFields: ["id"],
        },

        UserObject: {
          keyFields: ["email"],
        },

        VoiceConversationObject: {
          fields: {
            currentParticipants: {
              merge: (existing, incoming) => incoming,
            },
          },
        },

        WidgetObject: {
          keyFields: ["id", "kind"],
        },

        WhatsappHSMSupportedLanguageObject: {
          keyFields: ["languageCode"],
        },
      },
    }),
  });
};
