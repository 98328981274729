import { ApolloProvider } from "@apollo/client";
import { createContext, useContext, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import * as userActions from "@/actions/userActions";
import { wsConnectionStatusEnum } from "@/enums/networkEnum";
import * as userSelectors from "@/selectors/userSelectors";
import PageLoading from "@/src/components/PageLoading";
import { useValueRef } from "@/src/utils/hookUtils";
import { createApolloClient } from "./apolloClient";
import { createSubscriptionClient } from "./subscriptionClient";

const ClientNetworkContext = createContext({});

const ClientNetworkProvider = ({ children }) => {
  const dispatch = useDispatch();

  const [subscriptionClient, setSubscriptionClient] = useState(null);
  const [apolloClient, setApolloClient] = useState(null);

  const [wsConnectionStatus, setWsConnectionStatus] = useState(
    wsConnectionStatusEnum.initial,
  );

  const accessToken = useSelector(userSelectors.accessToken);

  const handleCreateApolloClient = ({ subscriptionClient }) => {
    const newApolloClient = createApolloClient({
      accessToken,
      subscriptionClient,
      authErrorCallback: () => {
        dispatch(userActions.logOutRequest());
      },
    });

    return newApolloClient;
  };

  const createClients = () => {
    setWsConnectionStatus(wsConnectionStatusEnum.inProgress);

    /* Close WS connection we have open if any */
    subscriptionClient?.close();

    const connectCallback = () => {
      setWsConnectionStatus(wsConnectionStatusEnum.connected);
    };

    const disconnectCallback = ({ subscriptionClient }) => {
      if (!subscriptionClient.closedByUser) {
        /* Attempting to reconnect immediately after server disconnect */
        setWsConnectionStatus(wsConnectionStatusEnum.tryReconnect);
        subscriptionClient.close(false, false);
      } else {
        /* Disconnect WS when user is offline */
        setWsConnectionStatus(wsConnectionStatusEnum.disconnected);
      }
    };

    const reconnectingCallback = () => {
      setWsConnectionStatus(wsConnectionStatusEnum.reconnecting);
    };

    const reconnectedCallback = ({ subscriptionClient }) => {
      /* Reinitialize apollo client to trigger queries refetch */
      const newApolloClient = handleCreateApolloClient({ subscriptionClient });

      setWsConnectionStatus(wsConnectionStatusEnum.connected);
      setApolloClient(newApolloClient);
    };

    const newSubscriptionClient = createSubscriptionClient({
      accessToken,
      connectCallback,
      disconnectCallback,
      reconnectingCallback,
      reconnectedCallback,
    });

    const newApolloClient = handleCreateApolloClient({
      subscriptionClient: newSubscriptionClient,
    });

    setSubscriptionClient(newSubscriptionClient);
    setApolloClient(newApolloClient);
  };

  const valueRefs = useValueRef({ subscriptionClient, createClients });

  /* This effect is responsible for creating the subscriptionClient and apolloClient  */
  useEffect(() => {
    const { createClients } = valueRefs.current;
    createClients();
  }, [valueRefs, accessToken]);

  /* Add event listeners for when the user goes offline and online */
  useEffect(() => {
    const offlineHandler = () => {
      const { subscriptionClient } = valueRefs.current;
      subscriptionClient?.close();
    };

    const onlineHandler = () => {
      const { createClients } = valueRefs.current;
      createClients();
    };

    window.addEventListener("offline", offlineHandler);
    window.addEventListener("online", onlineHandler);

    return () => {
      window.removeEventListener("offline", offlineHandler);
      window.removeEventListener("online", onlineHandler);
    };
  }, [valueRefs]);

  const clientNetworkContextValue = useMemo(() => {
    return { wsConnectionStatus };
  }, [wsConnectionStatus]);

  if (!apolloClient) return <PageLoading />;

  return (
    <ApolloProvider client={apolloClient}>
      <ClientNetworkContext.Provider value={clientNetworkContextValue}>
        {children}
      </ClientNetworkContext.Provider>
    </ApolloProvider>
  );
};

export const useClientNetworkContext = () => useContext(ClientNetworkContext);

export default ClientNetworkProvider;
