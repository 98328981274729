export const wsConnectionStatusEnum = Object.freeze({
  initial: "initial",
  inProgress: "inProgress",
  tryReconnect: "tryReconnect",
  reconnecting: "reconnecting",
  connected: "connected",
  disconnected: "disconnected",
});

export const serviceStatusSeverityEnum = {
  none: "none",
  minor: "minor",
  major: "major",
  critical: "critical",
};
